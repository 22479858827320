/* eslint-disable react/jsx-handler-names */
import React, { createRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Link from 'next/link'
import isEmpty from 'lodash/isEmpty'
import dynamic from 'next/dynamic'
import { withRouter } from 'next/router'
import nookies from 'nookies'
const Popover = dynamic(() => import('react-tiny-popover'))
import SvgAccount from '@ntuctech/devex-tangram/Icon/SvgAccount'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import MobileNavigation from './MobileNavigation/MobileNavigation'
import DeliveryMeter, { Cart } from './DeliveryMeter/DeliveryMeter'
import Text from '../Text/Text'
import NavigationList from './NavigationList/NavigationList'
import { AccountConsumer } from '../AccountProvider/AccountProvider'
import PcmForm from './PcmForm/PcmForm'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'
import HelpIcon from '../icons/HelpNew'
import HamburgerMenuIcon from '../icons/HamburgerMenu'
import GlobalContext from '../GlobalContext'
import { CartConsumer } from '../CartProvider/CartProvider'
import { totalProductCount } from '../CartProvider/utils'
import SearchBar, { SearchResultOnFirstRow } from './SearchBar/SearchBar'
import { SCREEN_SIZE, Media } from '../../lib/Media'
import TextIcon from '../TextIcon/TextIcon'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import { GTM_ENV_VAR as GEV, getNCheckFn } from '../../lib/eventTrackerFunction'
import trackGaVouchers from '../Vouchers/utils/trackGaVouchers'
import trackGaLuckyDraw from '../Campaigns/trackGaLuckyDraw'
import { boysBrigadeConfigFn } from '../../utils/boysBrigadeUtils'
import DeliveryAddressSwitchForm from '../DeliveryModeSwitcherForm'
import { STORE_TYPES } from '../../lib/orderStatus'
import DesktopLogo from '../icons/svg/logo-desktop-new.svg'
import DesktopLogoBusiness from '../icons/svg/logo-desktop-b2b-new.svg'
import MobileLogo from '../icons/svg/logo-mobile-new.svg'
import MobileLogoBusiness from '../icons/svg/logo-mobile-b2b-new.svg'
import { get } from '../../lib/fetch'
import { Router } from '../../routes'
import {
  HEADER_NAVIGATION_B2C,
  HEADER_NAVIGATION_B2B,
} from '../../lib/navigation'
import { getUserType } from '../../utils/userType'
import { B2B_TOOLTIP } from '../B2bTooltip/B2bTooltip'
import { AMP_EVENT_NAME, getPageTypeByUrl } from '../../lib/amplitude'
import { getCtaLocationMeta } from '../../lib/utils'
import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import SessionStorageService from '../../utils/SessionStorageService'
import {
  getCurrentSegmentSessionID,
  getUtmPayload,
  identifySegment,
} from '../../lib/segment'
import LocalStorageService from '../../utils/LocalStorageService'
import { Skeleton } from '@ntuctech/devex-tangram'
import { clearDySession } from '../../utils/DyUtils'

const B2bTooltip = dynamic(() => import('../B2bTooltip/B2bTooltip'))
const BusinessProfilePopup = dynamic(() =>
  import('../BusinessProfile/BusinessProfilePopup')
)
const GlobalPopoverStyle = createGlobalStyle`
  .react-tiny-popover-container {
    z-index: 10006;
  }
`
const StyledHeaderContainer = styled.div`
  width: 100%;
  padding: 1rem 1rem 0.3rem 1rem;
  margin: 0 auto;

  ${SCREEN_SIZE.From.Tablet} {
    padding: 1rem 2rem 0 2rem;
  }

  ${SCREEN_SIZE.From.Desktop} {
    padding: 1rem 1.25rem 0 1.25rem;
  }

  ${SCREEN_SIZE.From.UhdL} {
    max-width: 100rem;
  }
`

const MenuLinkStyle = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  > a {
    flex: 90%;
    color: #1557bf;
    text-decoration: none;
    display: block;
    text-transform: capitalize;
  }
  > svg {
    flex: 10%;
    transform: rotate(90deg);
  }
`

const StyledHeaderTop = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  ${SCREEN_SIZE.From.Tablet} {
    justify-content: space-between;
  }
`

const StyledDeliveryCart = styled.div`
  flex: 1;
  text-align: right;

  ${SCREEN_SIZE.Below.MobileL} {
    display: none;
  }

  ${SCREEN_SIZE.From.Desktop} {
    display: flex;
    align-items: flex-start;
    margin-left: auto;

    > span,
    > button {
      cursor: pointer;
    }
  }
`
const StyledA = styled.a`
  text-decoration: none;
  display: block;

  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.43;
  color: ${props => (props.$deliveryModeB2B ? '#FFF' : '#333333')};

  > svg {
    vertical-align: baseline;
    ${SCREEN_SIZE.From.Desktop} {
      vertical-align: text-top;
    }
    ${SCREEN_SIZE.From.Desktop} {
      margin-right: 0.25rem;
    }
  }
`

const AccountLinkWrapper = styled(TextIcon)`
  position: relative;
  display: inline-block;
  ${SCREEN_SIZE.From.Desktop} {
    :hover {
      > div {
        display: block;
      }
    }
  }
`

const AccountTextIcon = styled(TextIcon)`
  > svg {
    margin-right: 0.25rem;
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
  }
`

const HelpIconDisplay = styled(TextIcon)`
  margin-left: 1rem;
  > svg {
    margin-right: 0.25rem;
  }

  ${SCREEN_SIZE.From.Desktop} {
    margin-left: 1.5rem;
  }
`

const StyledHeaderBottom = styled.div`
  height: 3.125rem;
  display: flex !important;
  flex-direction: row;
  margin-top: 0.5rem;
`

const StyledWrapper = styled.div`
  justify-content: center;
  width: 100%;
  background-color: ${props => (props.$deliveryModeB2B ? '#0D3578' : '#FFF')};
  z-index: 9998;
  position: fixed;
  top: 0;
  border-bottom: 0.0625rem solid #eaeaea;
`

const PcmFormWrapper = styled.div`
  margin-left: auto;
  vertical-align: top;
  display: inline-flex;
  position: relative;
`

const PcmFormMobileWrapper = styled.div`
  display: block;
  height: auto;
  min-height: 2.75rem;
  padding: 0.5rem 1rem 0.5rem;
  transition: height 0.1s, overflow 0.1s ease 0.1s;
  background: #fff;
  text-align: left;
  position: relative;
  overflow: visible;

  > div {
    margin: 0;
  }
`

const StyledIconText = styled.span`
  display: none;
  ${SCREEN_SIZE.From.Desktop} {
    display: inline-block;
    white-space: nowrap;
  }
`

const NavigationListWrapper = styled.div`
  min-width: 27.25rem;
`

const HamburgerMenuIconWrapper = styled(HamburgerMenuIcon)`
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  color: ${props =>
    props.$deliveryModeB2B ? '#FFF' : defaultTheme.ColorBaseMineShaft500};
  ${SCREEN_SIZE.Only.Tablet} {
    margin-right: 1.25rem;
  }
  ${SCREEN_SIZE.Only.Desktop} {
    display: none;
  }
`

const GlobalTopPaddingBody = createGlobalStyle`
  body {
    padding-top: 6.65rem !important;
  }
  ${SCREEN_SIZE.From.Tablet} {
    body {
      padding-top: 6.35rem !important;
    }
  }
  ${SCREEN_SIZE.From.Desktop} {
    body {
      padding-top: 6.65rem !important;
    }
  }
`

const PopoverContainer = styled.div`
  margin: auto;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-shadow: 0 13px 15px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  width: 8rem;

  ${Text} {
    display: block;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: #f3f5f7;
    }
  }
`

const mobileLogo = props =>
  `url(${props.$deliveryModeB2B ? MobileLogoBusiness : MobileLogo})`
const desktopLogo = props =>
  `url(${props.$deliveryModeB2B ? DesktopLogoBusiness : DesktopLogo})`

const LogoHeader = styled.div`
  margin: 0;
  width: 5.375rem;
  height: 1.5rem;
  span {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: ${mobileLogo};
  }
  a,
  span {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  ${SCREEN_SIZE.From.Desktop} {
    width: 7.125rem;
    height: 2rem;
    span {
      background-image: ${desktopLogo};
    }
  }
`

const StyledPlaceholderDiv = styled.div`
  display: flex;
  flex: 1;
`

const DeliveryMeterContainer = styled.div`
  margin-left: auto;
`

const _getAccountType = accData => {
  const isDcMbr = getNCheckFn(
    accData,
    'memberships.fairprice_digital_club',
    null
  )
  const isJwcMbr = getNCheckFn(accData, 'memberships.just_wine_club', null)
  const isPlusMbr = getNCheckFn(accData, 'association.linkplus', null)
  const _accType = [
    ...(isDcMbr ? ['Digital Club'] : []),
    ...(isJwcMbr ? ['JWC'] : []),
    ...(isPlusMbr ? ['Plus'] : []),
  ]
  return _accType.join(',')
}
class PageHeader extends React.Component {
  constructor(props) {
    super(props)
    this.ref = createRef()

    let { [B2B_TOOLTIP]: b2bTooltip } = nookies.get()
    b2bTooltip = !isEmpty(b2bTooltip) ? JSON.parse(b2bTooltip) : {}
    this.state = {
      isLoggedIn: false,
      showNav: true,
      isMobileNavOpen: false,
      tooltipSeeAllOptions: false,
      showTooltip: false,
      storeType: undefined,
      organizationData: this.props.organizationData,
      deliveryModeB2B: false,
      isB2BModeEnabled: false,
      isSeenB2bTooltip: !!b2bTooltip?.opened,
      isOpenedB2bTooltip: !!b2bTooltip?.seen,
      showBusinessProfilePopup: false,
      isMounted: false,
    }

    this.getLoggedInMenuList = this.getLoggedInMenuList.bind(this)
    this.handleCloseMobileNav = this.handleCloseMobileNav.bind(this)
    this.handleOpenMobileNav = this.handleOpenMobileNav.bind(this)
    this.handleClickTracking = this.handleClickTracking.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.handleShowTooltip = this.handleShowTooltip.bind(this)
    this.getStoreType = this.getStoreType.bind(this)
    this.showStoreSelectorPopup = this.showStoreSelectorPopup.bind(this)
    this.updateB2bTooltipState = this.updateB2bTooltipState.bind(this)
    this.appBrowserOpened = this.appBrowserOpened.bind(this)
    this.callSetIdentify = this.callSetIdentify.bind(this)
    this.showBusinessProfilePopupState =
      this.showBusinessProfilePopupState.bind(this)
  }

  showStoreSelectorPopup() {
    if (!this.state.tooltipSeeAllOptions) {
      this.setState({ tooltipSeeAllOptions: true })
    }
  }

  updateOrganizationData = async () => {
    const storeId = this.props.checkoutAddress.storeId
    const url = storeId ? `organization?storeId=${storeId}` : `organization`
    const organizationResponse = await get(url)
    return organizationResponse.data
  }

  getStoreType(storeId) {
    return storeId === this.state.organizationData.defaultStoreId
      ? STORE_TYPES.PFC
      : STORE_TYPES.FFS
  }

  handleClickTracking(name) {
    if (name) {
      const { track } = this.props
      const processedName = name.length ? name.replace(/\s+/g, '-') : name

      const event = {
        [GEV.EVENT_CAT]: 'Ecommerce-Engagement',
        [GEV.EVENT_ACTION]: 'HeaderClick',
        [GEV.EVENT_LABEL]: `Loc=${processedName}`,
        ...(name === 'Login' && {
          [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.USER_LOGIN_SIGNUP_STARTED,
        }),
      }
      if (name === 'Logo') {
        event[GEV.AMPLITUDE_EVENT_NAME] = AMP_EVENT_NAME.HOME_CLICKED
      }
      if (name === 'Categories') {
        const { isEnabled, configuration } =
          getConfigFor(SPLIT_FEATURES.FE_SEGMENT_CATEGORY_MAIN_CLICKED) || {}
        const isSegmentEvent = isEnabled && configuration?.is_segment_on

        event[GEV.IS_SEGEMENT_EVENT] = isSegmentEvent
        event[GEV.AMPLITUDE_EVENT_NAME] = AMP_EVENT_NAME.CATEGORY_MAIN_CLICKED

        if (isSegmentEvent) {
          event[GEV.AMPLITUDE_CTA_SUBLOCATION] = ['header category']
          event[GEV.AMPLITUDE_CTA_SUBLOCATION_SECTION_TYPE] = ['navigation']
        }
      }
      if (name === 'Cart') {
        event[GEV.AMPLITUDE_EVENT_NAME] = AMP_EVENT_NAME.CART_CLICKED
      }
      if (name === 'Activity') {
        event[GEV.AMPLITUDE_EVENT_NAME] = AMP_EVENT_NAME.ORDERS_MAIN_CLICKED
      }
      if (name === 'Promotions') {
        const { isEnabled, configuration } =
          getConfigFor(SPLIT_FEATURES.FE_SEGMENT_PROMO_MAIN_CLICKED) || {}
        const isSegmentEvent = isEnabled && configuration?.is_segment_on

        event[GEV.AMPLITUDE_EVENT_NAME] = AMP_EVENT_NAME.PROMO_MAIN_CLICKED
        event[GEV.IS_SEGEMENT_EVENT] = isSegmentEvent
      }
      if (name === 'Recipes') {
        event[GEV.AMPLITUDE_EVENT_NAME] = AMP_EVENT_NAME.RECIPE_MAIN_CLICKED
        event[GEV.AMPLITUDE_CTA_SUBLOCATION] = ['more', 'recipe']
        event[GEV.AMPLITUDE_CTA_SUBLOCATION_SECTION_TYPE] = 'navigation'
        event[GEV.ASSET_TYPE] = 'Lifestyle'
      }

      event[GEV.AMPLITUDE_CTA_LOCATION] = {
        pageName: getPageTypeByUrl(),
        pageMeta: getCtaLocationMeta() || [],
      }

      track(event)

      //track whenever users open the mobile menu
      if (name === 'MobileMenuOpen') {
        track({
          [GEV.EVENT_CAT]: 'PromotionFunnel',
          [GEV.EVENT_ACTION]: 'PromotionImpressions',
          [GEV.EVENT_LABEL]: 'Menu_More_Text Link',
          [GEV.EVENT]: 'promoImpression',
        })
      }
      if (name === 'Vouchers') {
        trackGaVouchers(this.props)
      }
      if (name === 'Lucky draw') {
        trackGaLuckyDraw(this.props)
      }
      const { deliveryModeB2B } = this.state
      const headerLinks = deliveryModeB2B
        ? HEADER_NAVIGATION_B2B
        : HEADER_NAVIGATION_B2C

      //track whenever users click on any More's menu item
      if (headerLinks.find(link => link.label === name)) {
        track({
          [GEV.EVENT_CAT]: 'PromotionFunnel',
          [GEV.EVENT_ACTION]: 'PromotionClick',
          [GEV.EVENT_LABEL]: `Menu_More_Text Link - ${name}`,
          [GEV.EVENT]: 'promotionClick',
        })
      }

      if (name === 'Logout') {
        // TODO: Clear user properties
        track({
          [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.LOGGED_OUT,
        })
      }
    }
  }

  handleLogout(e) {
    e.preventDefault()
    this.handleClickTracking('Logout')
    clearDySession()
    this.props.logout()
  }

  handleLogin(e) {
    e.preventDefault()
    this.handleClickTracking('Login')
    const redirectPath = Router && Router.router ? Router.router.asPath : '/'
    window.location.href = `/login?redirect=${encodeURIComponent(redirectPath)}`
  }

  handleCloseMobileNav() {
    this.setState({ isMobileNavOpen: false })
    this.handleClickTracking('MobileMenuClose')
  }

  handleOpenMobileNav() {
    this.setState({ isMobileNavOpen: true })
    this.handleClickTracking('MobileMenuOpen')
  }

  handleShowTooltip(storeType = '') {
    this.setState({ showTooltip: false, storeType: storeType })
  }

  callSetIdentify(shoppingMode) {
    const { isLoggedIn, accountData } = this.props
    const { query } = this.props.router

    const userId = accountData?.uid || null
    const userType = shoppingMode ? shoppingMode : getUserType() || 'b2c'
    const accountType = !isLoggedIn ? null : _getAccountType(accountData)

    const setUtmPayload = getUtmPayload(query, true)

    if (query) {
      // storing UTM_PAYLOAD for current session identify calls
      SessionStorageService.setItem(
        'utm_payload',
        JSON.stringify(setUtmPayload)
      )
    }

    const utmPayload = getUtmPayload(query)
    const traits = {
      'userId': !isLoggedIn ? null : userId,
      'online_fp_id': !isLoggedIn ? null : accountData?.id.toString(),
      'ga client id':
        document.cookie.match(/_ga=(.+?);/) &&
        document.cookie.match(/_ga=(.+?);/).length
          ? document.cookie
              .match(/_ga=(.+?);/)[1]
              .split('.')
              .slice(-2)
              .join('.')
          : null,
      'link plus id': getNCheckFn(accountData, 'association.linkplus') || null,
      'customer login status': !isLoggedIn ? false : true,
      'login/signup source': 'grocery-online',
      'account type':
        accountType &&
        typeof accountType === 'string' &&
        accountType.toLowerCase(),
      'experiment': this.props.getTestVersions(),
      'shopping mode':
        userType && typeof userType === 'string' && userType.toLowerCase(),
      'user type': isLoggedIn ? 'existing' : 'not logged in',
      ...utmPayload,
    }
    identifySegment({ ...traits }, userId)
  }

  appBrowserOpened() {
    // App/Browser Event
    // b/o (browser/opened)
    const IsBrowserOpened = SessionStorageService.getItem('b/o')

    if (!IsBrowserOpened) {
      const { isEnabled, configuration } =
        getConfigFor(SPLIT_FEATURES.FE_SEGMENT_APP_BROWSER_OPENED) || {}
      const isSegmentEvent = isEnabled && configuration?.is_segment_on

      SessionStorageService.setItem('b/o', JSON.stringify(true))
      // send event to segment only skipping amplitude
      const payload = {
        [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.APP_BROWSER_OPENED,
        [GEV.IS_SEGEMENT_EVENT]: isSegmentEvent,
        [GEV.SEGMENT_SCREEN_CATEGORY]: 'omni',
        [GEV.ASSET_TYPE]: 'OMNI',
        [GEV.AMPLITUDE_CTA_LOCATION]: null,
        [GEV.AMPLITUDE_CTA_SUBLOCATION]: null,
      }

      if (isSegmentEvent) {
        this.props.track(payload)
      }

      const {
        isEnabled: isIdentifyEnabled,
        configuration: identifyConfiguration,
      } = getConfigFor(SPLIT_FEATURES.FE_SEGMENT_IDENTIFY_CALLS) || {}
      const isIdentifySegmentEnabled =
        isIdentifyEnabled && identifyConfiguration?.is_segment_on

      setTimeout(() => {
        // Check if there is any last_session_id
        // web component loads first and that time not able to get value from localstorage
        // as segment promise not resolved to make session key in browser
        // so have to make some delay to set this property on localStorage
        const lastSegmentSessionId = LocalStorageService.getItem(
          'last_analytics_session_id'
        )

        const segmentCurrentId = getCurrentSegmentSessionID()
        if (
          isIdentifySegmentEnabled &&
          (!lastSegmentSessionId || lastSegmentSessionId !== segmentCurrentId)
        ) {
          // calling identify method for segment
          LocalStorageService.setItem(
            'last_analytics_session_id',
            segmentCurrentId
          )
          this.callSetIdentify()
        }
      }, 2500)
    }
  }

  componentDidMount() {
    // Call segment on page load
    this.appBrowserOpened()

    const { isLoggedIn } = this.props
    if (isLoggedIn) {
      this.setState({
        isLoggedIn: isLoggedIn,
      })
    }
    const userType = getUserType()
    this.setState({
      deliveryModeB2B: userType === 'B2B',
      isB2BModeEnabled: userType !== null,
    })
    const currentStoreType = this.getStoreType(
      this.props.checkoutAddress?.storeId
    )
    if (
      (currentStoreType === STORE_TYPES.PFC &&
        this.props.checkoutAddress?.availableStores?.length > 1) ||
      this.state.deliveryModeB2B
    ) {
      this.handleShowTooltip(currentStoreType)
    }

    this.setState({
      isMounted: true,
    })
  }

  /* istanbul ignore next */
  showBusinessProfilePopupState = () => {
    this.setState({ showBusinessProfilePopup: true })
  }

  updateB2bTooltipState = (isOpened = true, isSeen = false) => {
    this.setState(
      {
        isOpenedB2bTooltip: isOpened,
        isSeenB2bTooltip: isSeen,
      },
      () => {
        const currentStoreType = this.getStoreType(
          this.props.checkoutAddress?.storeId
        )
        this.handleShowTooltip(currentStoreType)
      }
    )
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.checkoutAddress &&
      this.props.checkoutAddress.storeId !== prevProps.checkoutAddress.storeId
    ) {
      const organizationData = await this.updateOrganizationData()
      this.setState({
        organizationData,
      })
    }
  }

  getLoggedInMenuList(deliveryModeB2B) {
    const { isShownPopover } = this.state
    return (
      <Popover
        isOpen={isShownPopover}
        position={['bottom']}
        onClickOutside={() => {
          this.setState({ isShownPopover: false })
        }}
        content={
          <PopoverContainer
            onMouseLeave={e => {
              e.preventDefault()
              e.stopPropagation()
              this.setState({ isShownPopover: false })
            }}
            onMouseEnter={() => {
              this.setState({ isShownPopover: true })
            }}
          >
            <MenuLinkStyle key={'accounts'}>
              <Link href={'/accounts'} passHref legacyBehavior>
                <a
                  onClick={() => {
                    this.handleClickTracking('Account')
                    this.setState({ isShownPopover: false })
                  }}
                  data-testid="navAccountPageLink"
                >
                  Account
                </a>
              </Link>
            </MenuLinkStyle>
            <MenuLinkStyle key={'orders'}>
              <Link
                href={'/orders'}
                passHref
                onClick={() => {
                  this.handleClickTracking('Orders')
                  this.setState({ isShownPopover: false })
                }}
                data-testid="navOrderPageLink"
              >
                Orders
              </Link>
            </MenuLinkStyle>
            <MenuLinkStyle
              key={'Logout'}
              style={{ borderTop: '1px solid #eaeaea' }}
            >
              <a
                href="/logout"
                onClick={e => {
                  this.handleLogout(e)
                  this.setState({ isShownPopover: false })
                }}
                style={{ color: '#dd0d42' }}
                data-testid="navLogoutLink"
              >
                Logout
              </a>
            </MenuLinkStyle>
          </PopoverContainer>
        }
      >
        <Link href="/accounts" passHref legacyBehavior>
          <StyledA
            aria-label="Account"
            $deliveryModeB2B={deliveryModeB2B}
            isLoggedIn={true}
            data-testid="accountPageLink"
            onClick={() => {
              this.handleClickTracking('Account')
            }}
            onMouseOver={e => {
              e.preventDefault()
              e.stopPropagation()
              this.setState({ isShownPopover: true })
            }}
            onMouseLeave={e => {
              e.preventDefault()
              e.stopPropagation()
              this.setState({ isShownPopover: false })
            }}
          >
            <AccountTextIcon
              prefix={
                <SvgAccount color={deliveryModeB2B ? '#FFF' : '#696969'} />
              }
            >
              <StyledIconText>Account</StyledIconText>
            </AccountTextIcon>
          </StyledA>
        </Link>
      </Popover>
    )
  }

  render() {
    const {
      categoryList,
      showPostalCodeModule,
      count,
      additionalCharges,
      onHandleBackdrop,
      loading,
      isLoggedIn,
      logout,
      checkoutAddress,
      update,
      track,
      remoteConfig,
    } = this.props
    const {
      organizationData,
      deliveryModeB2B,
      isB2BModeEnabled,
      isSeenB2bTooltip,
      showBusinessProfilePopup,
    } = this.state
    let isBoysBrigade = false
    if (remoteConfig) {
      const { enabled, storeList } = boysBrigadeConfigFn()
      const currentStoreId = checkoutAddress?.storeId || ''
      isBoysBrigade = enabled && storeList?.includes(currentStoreId)
    }

    return (
      <React.Fragment>
        {isLoggedIn && showBusinessProfilePopup && (
          <BusinessProfilePopup open={showBusinessProfilePopup} />
        )}
        <GlobalPopoverStyle />
        <GlobalTopPaddingBody />
        <StyledWrapper
          id="header-container"
          $deliveryModeB2B={deliveryModeB2B}
          ref={this.ref}
        >
          <StyledHeaderContainer id="styledHeader">
            <StyledHeaderTop>
              <Media query={SCREEN_SIZE.Below.Desktop}>
                <HamburgerMenuIconWrapper
                  onClick={this.handleOpenMobileNav}
                  $deliveryModeB2B={deliveryModeB2B}
                />
                <MobileNavigation
                  deliveryModeB2B={deliveryModeB2B}
                  handleClose={this.handleCloseMobileNav}
                  navShow={this.state.isMobileNavOpen}
                  router={this.props.router}
                  isLoggedIn={isLoggedIn}
                  logout={logout}
                  handleClickTracking={this.handleClickTracking}
                  handleLogout={this.handleLogout}
                  handleLogin={this.handleLogin}
                  isBoysBrigade={isBoysBrigade}
                  hasPlusAlert={this.props.hasPlusAlert}
                  updatePlusAlert={this.props.updatePlusAlert}
                  hasNewVouchers={this.props.hasNewVouchers}
                  hasPaymentAlert={this.props.hasPaymentAlert}
                  track={track}
                />
              </Media>
              <LogoHeader $deliveryModeB2B={deliveryModeB2B}>
                <Link
                  href="/"
                  passHref
                  aria-label="FairPrice Logo"
                  data-testid="fairPriceLogo"
                  onClick={() => this.handleClickTracking('Logo')}
                >
                  <span />
                </Link>
              </LogoHeader>
              {isB2BModeEnabled && (
                <DeliveryAddressSwitchForm
                  deliveryModeB2B={deliveryModeB2B}
                  callSetIdentify={this.callSetIdentify}
                />
              )}
              <StyledDeliveryCart>
                <PcmFormWrapper>
                  <PcmForm
                    organizationData={organizationData}
                    checkoutAddress={checkoutAddress}
                    updateCheckoutAddress={update}
                    isLoggedIn={isLoggedIn}
                    handleClickTracking={this.handleClickTracking}
                    isDesktopView={true}
                    tooltipSeeAllOptions={this.state.tooltipSeeAllOptions}
                    deliveryModeB2B={deliveryModeB2B}
                    loading={loading}
                    additionalCharges={additionalCharges}
                    count={count}
                  />
                </PcmFormWrapper>
                <AccountLinkWrapper>
                  {this.state.isMounted ? (
                    isLoggedIn ? (
                      this.getLoggedInMenuList(deliveryModeB2B)
                    ) : (
                      <StyledA
                        aria-label="Login/Sign up"
                        $deliveryModeB2B={deliveryModeB2B}
                        href="/login"
                        isLoggedIn={false}
                        data-testid="loginPageLink"
                        onClick={e => {
                          this.handleLogin(e)
                        }}
                      >
                        <AccountTextIcon
                          prefix={
                            <SvgAccount
                              color={
                                deliveryModeB2B
                                  ? '#FFF'
                                  : defaultTheme.ColorBaseMineShaft500
                              }
                            />
                          }
                        >
                          <StyledIconText>Login/Sign up</StyledIconText>
                        </AccountTextIcon>
                      </StyledA>
                    )
                  ) : (
                    <AccountTextIcon
                      prefix={
                        <SvgAccount
                          color={deliveryModeB2B ? '#FFF' : '#696969'}
                        />
                      }
                    >
                      <StyledIconText>
                        <Skeleton variant="rectangle" width={150} height={24} />
                      </StyledIconText>
                    </AccountTextIcon>
                  )}
                  {isLoggedIn && !isSeenB2bTooltip && deliveryModeB2B && (
                    <Media query={SCREEN_SIZE.From.Tablet}>
                      <B2bTooltip
                        remoteConfig={this.props.remoteConfig}
                        router={this.props.router}
                        updateB2bTooltipState={this.updateB2bTooltipState}
                        showBusinessProfilePopupState={
                          this.showBusinessProfilePopupState
                        }
                      />
                    </Media>
                  )}
                </AccountLinkWrapper>
                <TextIcon>
                  <StyledA
                    aria-label="Help Centre"
                    $deliveryModeB2B={deliveryModeB2B}
                    onClick={() => this.handleClickTracking('Help')}
                    href="https://help.fairprice.com.sg/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HelpIconDisplay
                      prefix={
                        <HelpIcon
                          width="16px"
                          height="16px"
                          color={
                            deliveryModeB2B
                              ? '#FFF'
                              : defaultTheme.ColorBaseMineShaft500
                          }
                        />
                      }
                    >
                      <StyledIconText>Help Centre</StyledIconText>
                    </HelpIconDisplay>
                  </StyledA>
                </TextIcon>
              </StyledDeliveryCart>
            </StyledHeaderTop>
            <StyledHeaderBottom>
              {isBoysBrigade && <StyledPlaceholderDiv />}
              {!isBoysBrigade && (
                <Media query={SCREEN_SIZE.From.Desktop}>
                  <NavigationListWrapper data-testid="desktop-navigation">
                    <NavigationList
                      categoryList={categoryList}
                      deliveryModeB2B={deliveryModeB2B}
                      organizationData={organizationData}
                      showPostalCodeModule={showPostalCodeModule}
                      count={count}
                      onHandleBackdrop={onHandleBackdrop}
                      isLoggedIn={isLoggedIn}
                      query={SCREEN_SIZE.From.Desktop}
                      router={this.props.router}
                      handleClickTracking={this.handleClickTracking}
                      callSetIdentify={this.callSetIdentify}
                    />
                  </NavigationListWrapper>
                </Media>
              )}
              {!isBoysBrigade && (
                <SearchBar
                  deliveryModeB2B={deliveryModeB2B}
                  dataTestId={'desktop'}
                  searchResultStyle={SearchResultOnFirstRow}
                  checkoutAddress={checkoutAddress}
                  isLoggedIn={isLoggedIn}
                  onHandleBackdrop={onHandleBackdrop}
                />
              )}
              <DeliveryMeterContainer>
                <DeliveryMeter
                  loading={loading}
                  deliveryModeB2B={deliveryModeB2B}
                  organizationData={organizationData}
                  additionalCharges={additionalCharges}
                  count={count}
                  query={SCREEN_SIZE.From.Tablet}
                />
              </DeliveryMeterContainer>
              <Cart
                {...this.props}
                deliveryModeB2B={deliveryModeB2B}
                query={SCREEN_SIZE.From.Tablet}
                handleClickTracking={this.handleClickTracking}
              />
            </StyledHeaderBottom>
          </StyledHeaderContainer>
        </StyledWrapper>
        {this.props.router?.pathname === '/' && (
          <Media query={SCREEN_SIZE.Only.Mobile}>
            <PcmFormMobileWrapper>
              <PcmForm
                organizationData={organizationData}
                checkoutAddress={checkoutAddress}
                updateCheckoutAddress={update}
                isLoggedIn={isLoggedIn}
                handleClickTracking={this.handleClickTracking}
                isDesktopView={false}
                tooltipSeeAllOptions={this.state.tooltipSeeAllOptions}
                deliveryModeB2B={deliveryModeB2B}
              />
            </PcmFormMobileWrapper>
          </Media>
        )}
      </React.Fragment>
    )
  }
}

const PageHeaderWrapper = props => (
  <GlobalContext.Consumer>
    {({ remoteConfig }) => (
      <AccountConsumer>
        {({
          isLoggedIn,
          logout,
          login,
          account,
          hasPlusAlert,
          updatePlusAlert,
          accountData,
        }) => (
          <CheckoutAddressConsumer>
            {({ checkoutAddress, update }) => (
              <EventTrackingConsumer>
                {({ track, getTestVersions }) => (
                  <CartConsumer>
                    {({ additionalCharges, items, loading }) => (
                      <PageHeader
                        count={totalProductCount(items)}
                        items={items}
                        additionalCharges={additionalCharges}
                        loading={loading}
                        isLoggedIn={isLoggedIn}
                        logout={logout}
                        login={login}
                        account={account}
                        accountData={accountData}
                        getTestVersions={getTestVersions}
                        track={track}
                        checkoutAddress={checkoutAddress}
                        update={update}
                        remoteConfig={remoteConfig}
                        hasPlusAlert={hasPlusAlert}
                        updatePlusAlert={updatePlusAlert}
                        {...props}
                      />
                    )}
                  </CartConsumer>
                )}
              </EventTrackingConsumer>
            )}
          </CheckoutAddressConsumer>
        )}
      </AccountConsumer>
    )}
  </GlobalContext.Consumer>
)

PageHeaderWrapper.defaultProps = {
  organizationData: {},
}

export default withRouter(PageHeaderWrapper)

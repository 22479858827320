import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import PropTypes from 'prop-types'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import Text from '../../Text/Text'
import CartIcon from '../../icons/CartNewActive'
import InactiveCartIcon from '../../icons/CartNewInactive'
import { SCREEN_SIZE, withMedia } from '../../../lib/Media'
import InfoIcon from '../../../static/Info/InfoIcon'
import DotLoader from '../../DotLoader/DotLoader'
import dynamic from 'next/dynamic'
const ShippingTierTooltip = dynamic(() => import('../../ShippingTierTooltip'))
import GlobalContext from '../../GlobalContext'
import { CheckoutAddressConsumer } from '../../CheckoutAddressProvider/CheckoutAddressProvider'

const DeliveryMeterDisplayCondition = styled.div`
  text-align: right;
  margin-top: ${props => (props.$isInsidePcm ? 0 : '.1rem')};
  display: flex;
  align-items: center;

  ${SCREEN_SIZE.From.Tablet} {
    margin-top: 0.625rem;
    margin-right: 0.7rem;

    ${SCREEN_SIZE.From.Desktop} {
      margin-right: 0.75rem;
    }

    ${SCREEN_SIZE.From.Hd} {
      margin-right: 1rem;
    }
  }
`

const StyledLoaderWrapper = styled.div`
  width: 7.5rem;
  text-align: right;
  margin-top: 0.5rem;
  position: relative;

  ${SCREEN_SIZE.From.Tablet} {
    margin-top: 1rem;
    padding-right: 1rem;
  }
`

const StyledText = styled(Text)`
  line-height: ${props => (props.$isInsidePcm ? 0.75 : 1.71)};
  align-items: center;
  font-size: ${props => (props.$isInsidePcm ? '.75rem' : '.875rem')};
`

const StyledServiceText = styled(StyledText)`
  white-space: nowrap;
  color: ${props =>
    props.$isB2B ? '#fff' : defaultTheme.ColorBaseMineShaft500};

  ${SCREEN_SIZE.Only.Mobile} {
    color: ${defaultTheme.ColorBaseMineShaft500};
  }
`

const StyledCartButton = styled.a`
  font-size: 1rem;
  line-height: 1.5;
  color: ${props =>
    props.deliveryModeB2B ? '#FFF' : defaultTheme.ColorBaseBrandPrimary500};
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 0.625rem 0;

  ${SCREEN_SIZE.From.Tablet} {
    min-width: 4.125rem;
  }
  span {
    display: inline-block;
    vertical-align: top;
  }
`

const StyledCartIconWrapper = styled.span`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 0.7rem;

  ${SCREEN_SIZE.From.Tablet} {
    margin-right: 0.5rem;
  }
`

const CartCount = styled.span`
  height: 1rem;
  max-width: 25px;
  position: absolute;
  background-color: ${defaultTheme.ColorBaseShiraz500};
  border-radius: 0.25rem;
  color: #fff;
  font-size: 0.6875rem;
  line-height: 1.45;
  text-align: center;
  padding: 0rem 0.23rem;
  top: -0.48rem;
  left: 0.65rem;
`

const StyledTextDelivery = styled.span`
  display: inline-block;
  vertical-align: top;
  line-height: ${props => (props.$isInsidePcm ? 0.75 : 1.71)};
`

const StyledCartText = styled.div`
  display: none;

  ${SCREEN_SIZE.From.Tablet} {
    display: inline-block;
  }
`

const InfoIconWrapper = styled.span`
  display: inline-block;
  margin-left: 0.125rem;
  margin-top: 0.2rem;
  cursor: pointer;
  position: relative;

  & path {
    fill: ${props =>
      props.$isInsidePcm || !props.$isB2B
        ? defaultTheme.ColorBaseMineShaft500
        : '#fff'};
  }
`

const DeliveryMeterContainer = styled.div`
  display: ${props => (props.$isInsidePcm ? 'block' : 'none')};
  ${SCREEN_SIZE.From.MobileL} {
    display: flex;
  }
  align-items: center;
  margin-left: ${props => (props.$isInsidePcm ? 0 : 'auto')};
`

class DeliveryMeter extends React.Component {
  handleShowShippingTierTooltip = e => {
    // to prevent event bubbling as we have location selection in mobile view
    e.stopPropagation()
    this.setState({ isShownShippingTierTooltip: true })
  }

  handleHideShippingTierTooltip = () =>
    this.setState({ isShownShippingTierTooltip: false })

  render() {
    const { loading, deliveryModeB2B, isInsidePcm } = this.props
    if (loading) {
      return (
        <StyledLoaderWrapper data-testid="deliveryMeterLoader">
          <DotLoader size="7" />
        </StyledLoaderWrapper>
      )
    }

    const { isShownShippingTierTooltip } = this.state || {}

    return (
      <DeliveryMeterDisplayCondition $isInsidePcm={isInsidePcm}>
        <DeliveryMeterContainer $isInsidePcm={isInsidePcm}>
          <StyledText
            $isInsidePcm={isInsidePcm}
            size="medium"
            color={isInsidePcm ? '#333' : deliveryModeB2B ? '#FFF' : '#333'}
            weight="normal"
          >
            <StyledTextDelivery $isInsidePcm={isInsidePcm}>
              <StyledServiceText $isB2B={deliveryModeB2B}>
                Fees may apply
              </StyledServiceText>
            </StyledTextDelivery>
          </StyledText>
          <InfoIconWrapper
            $isB2B={deliveryModeB2B}
            $isInsidePcm={isInsidePcm}
            onClick={this.handleShowShippingTierTooltip}
          >
            <InfoIcon />
            {isShownShippingTierTooltip && (
              <ShippingTierTooltip
                isHeaderTooltip={true}
                onClose={this.handleHideShippingTierTooltip}
                arrowLeft="1rem"
                arrowTop="0.8rem"
                tooltipLeft={!isInsidePcm ? '-13.4625rem' : '-2.4625rem'}
              />
            )}
          </InfoIconWrapper>
        </DeliveryMeterContainer>
      </DeliveryMeterDisplayCondition>
    )
  }
}

export class Cart extends React.Component {
  render() {
    const { count, handleClickTracking, deliveryModeB2B } = this.props
    return (
      <Link href="/cart" prefetch={false} passHref legacyBehavior>
        <StyledCartButton
          deliveryModeB2B={deliveryModeB2B}
          onClick={() => handleClickTracking('Cart')}
          data-testid="cart-button"
        >
          <StyledCartIconWrapper>
            {count > 0 && <CartCount> {count > 99 ? '99+' : count} </CartCount>}
            {count ? (
              <CartIcon
                width="1.5rem"
                height="1.5rem"
                title="Shopping cart"
                color={
                  deliveryModeB2B
                    ? '#FFF'
                    : defaultTheme.ColorBaseBrandPrimary500
                }
              />
            ) : (
              <InactiveCartIcon
                width="1.5rem"
                height="1.5rem"
                title="Shopping cart"
              />
            )}
          </StyledCartIconWrapper>
          <StyledCartText>Cart</StyledCartText>
        </StyledCartButton>
      </Link>
    )
  }
}

Cart.defaultProps = {
  count: 0,
  handleClickTracking: PropTypes.func.isRequired,
}

DeliveryMeter.defaultProps = {
  organizationData: {},
  additionalCharges: {},
  minSpendForFreeDelivery: '',
}

const DeliveryMeterWrapper = props => {
  return (
    <CheckoutAddressConsumer>
      {({ checkoutAddress }) => (
        <GlobalContext.Consumer>
          {({ remoteConfig }) => (
            <DeliveryMeter
              {...props}
              remoteConfig={remoteConfig}
              checkoutAddress={checkoutAddress}
            />
          )}
        </GlobalContext.Consumer>
      )}
    </CheckoutAddressConsumer>
  )
}

export default withMedia(DeliveryMeterWrapper)

import React, { useEffect } from 'react'
import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider'
import Link from 'next/link'
import { AutoSizer, Grid } from 'react-virtualized'
import s from './styles'
import SvgBubblePlaceholder from '../icons/BubblePlaceholder'

const DefaultImage = ({ columnWidth }) => {
  return (
    <s.PlaceholderImage columnWidth={columnWidth} data-testid="default-image">
      <SvgBubblePlaceholder width={28} height={28} />
    </s.PlaceholderImage>
  )
}

const CardImage = ({ imgSrc, columnWidth }) => {
  const [isBrokenImage, setIsBrokenImage] = React.useState(false)
  return (
    <>
      {imgSrc ? (
        <div>
          {!isBrokenImage ? (
            <s.Image
              src={imgSrc}
              columnWidth={columnWidth}
              onError={() => {
                setIsBrokenImage(true)
              }}
            />
          ) : (
            <DefaultImage columnWidth={columnWidth} />
          )}
        </div>
      ) : (
        <DefaultImage />
      )}
    </>
  )
}

function PopularCategory({
  isSuggestion,
  isTrendingloading,
  handleSuggestionClick,
  data,
  ...props
}) {
  const columnCount = data.length
  const mediaQueryList = window.matchMedia('(min-width: 768px)')
  const [gap, setGap] = React.useState(mediaQueryList.matches ? 32 : 8)
  const [columnWidth, setColumnWidth] = React.useState(
    mediaQueryList.matches ? 72 : 64
  )

  useEffect(() => {
    const handleMediaQueryChange = e => {
      setGap(e.matches ? 32 : 8)
      setColumnWidth(e.matches ? 72 : 64)
    }
    mediaQueryList.addEventListener('change', handleMediaQueryChange)
    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange)
    }
  }, [mediaQueryList])

  const handleClick = info => {
    handleSuggestionClick(info, 'PopularCategory')
  }

  const handleLinks = (info, flag) => {
    let url = ''

    switch (info.pageType) {
      case 'search':
        url = `/search?query=${info?.slug}&type=popularcategory`
        break
      case 'brand':
        url = `/brand/${info?.slug}&type=popularcategory`
        break
      case 'category':
        url = `/category/${info?.slug}&type=popularcategory`
        break
      case 'tag':
        url = `/tag/${info?.slug}&type=popularcategory`
        break
    }

    if (flag) {
      url = url.split('&')[0]
    }

    return url
  }
  return (
    <NdsThemeProvider>
      <s.StyledTrendWrapper isSuggestion={isSuggestion} {...props}>
        {!isTrendingloading && data.length ? (
          <>
            <s.StyledHeading data-testid="trending-heading">
              Popular Categories
            </s.StyledHeading>
            <s.ScrollContainer>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Grid
                    key={gap}
                    width={width}
                    height={112}
                    columnCount={columnCount}
                    columnWidth={({ index }) => {
                      return index === columnCount - 1
                        ? columnWidth
                        : columnWidth + gap
                    }}
                    rowCount={1}
                    rowHeight={112}
                    cellRenderer={({ key, columnIndex, style }) => {
                      const info = data[columnIndex]
                      return (
                        <s.StyledCategory key={key} style={style}>
                          <Link
                            onClick={() => handleClick(info)}
                            href={handleLinks(info, false)}
                            as={handleLinks(info, true)}
                            passHref
                            prefetch={false}
                          >
                            <s.CardWrap columnWidth={columnWidth}>
                              <CardImage
                                columnWidth={columnWidth}
                                imgSrc={info?.imageUrl}
                              />
                              <s.CardTitle>
                                <s.TruncateTextContainer lineClamp={2}>
                                  {info?.name}
                                </s.TruncateTextContainer>
                              </s.CardTitle>
                            </s.CardWrap>
                          </Link>
                        </s.StyledCategory>
                      )
                    }}
                    overscanColumnCount={10}
                  />
                )}
              </AutoSizer>
            </s.ScrollContainer>
          </>
        ) : (
          ''
        )}
      </s.StyledTrendWrapper>
    </NdsThemeProvider>
  )
}

export default PopularCategory

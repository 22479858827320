import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import { StyledPopup } from '../../Popup/StyledPopup'
import { SCREEN_SIZE, visible } from '../../../lib/Media'
import SearchIcon from '../../icons/SearchNew'
import RemoveIcon from '../../icons/Remove'
import RecentSearch from '../../icons/RecentSearch'

const styles = {}

styles.StyledSubmit = styled.button`
  display: none;
`

styles.StyledInput = styled.input`
  font-family: inherit;
  width: 100%;
  padding: 0.625rem 1.75rem 0.625rem 2.2rem;
  position: absolute;
  top: 0;
  left: 0;
  flex-grow: 1;
  color: ${props =>
    props.$deliveryModeB2B ? '#FFF' : defaultTheme.ColorBaseMineShaft700};
  font-size: 1rem;
  line-height: 1.43;
  background: transparent;
  outline: none;
  z-index: 1;
  background-color: ${props =>
    props.$deliveryModeB2B ? '#104393' : defaultTheme.ColorBaseMineShaft40};
  border: ${props =>
    props.$deliveryModeB2B
      ? '1px solid #1557BF'
      : `1px solid ${defaultTheme.ColorBaseMineShaft80}`};
  border-radius: 0.25rem;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: none;
  ${SCREEN_SIZE.From.Tablet} {
    font-size: 0.875rem;
  }

  ::placeholder {
    color: ${props =>
      props.$deliveryModeB2B ? '#A3C2F5' : defaultTheme.ColorBaseMineShaft300};
  }

  /* clears the 'X' from Internet Explorer */
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`

styles.StyledSearchIcon = styled(SearchIcon)`
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: ${props =>
    props.$deliveryModeB2B ? '#A3C2F5' : defaultTheme.ColorBaseMineShaft500};
  margin-top: 0.25rem;
  ${SCREEN_SIZE.From.Desktop} {
    margin-top: 0;
  }
`

styles.StyledClearIcon = styled(RemoveIcon)`
  height: 1rem;
  width: 1rem;
  margin-left: auto;
  position: absolute;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${props => (props.$deliveryModeB2B ? '#A3C2F5' : '#696969')};
  z-index: 1;
  margin-top: 0.25rem;
  ${SCREEN_SIZE.From.Desktop} {
    margin-top: 0;
  }
`

styles.StyledSuggestionWrapper = styled.div`
  ${SCREEN_SIZE.Below.Tablet} {
    height: 100vh;
    position: absolute;
    top: 3.125rem;
  }

  ${SCREEN_SIZE.Only.Tablet} {
    position: static;
  }
`

styles.StyledPopupWrapper = styled(StyledPopup)`
  ${visible(SCREEN_SIZE.From.Tablet)}
`

styles.StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 2.6rem;
  box-sizing: border-box;

  ${styles.StyledPopupWrapper} {
    display: none;
  }
`

styles.StyledInputWrapper = styled.div`
  width: 40rem;
  margin-right: 2.5rem;
  ${SCREEN_SIZE.Only.Mobile} {
    margin-right: 1.2rem;
  }
`

styles.StyledRecentSearchContainer = styled.div`
  display: block;
  padding-bottom: 0.25rem;
  border-bottom: none;
  margin-bottom: ${props => (props.border ? '0.5rem' : 'inherit')};
`

styles.StyledRecentSearchIcon = styled(RecentSearch)`
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 0.25rem;
  vertical-align: top;
`

styles.StyledRecentSearchTerm = styled.a`
  color: #333;
  line-height: 1.4375rem;
  padding: 0.5rem 0;
  display: block;
  text-decoration: none;

  &:hover {
    color: #1557bf;
  }

  :last-child {
    padding-bottom: 1rem;
  }
`

styles.SearchResult = styled.div`
  ${SCREEN_SIZE.Only.Mobile} {
    overflow: auto;
  }
  position: relative;
  overflow: hidden;
  margin-top: 1rem;
  z-index: 3;
  top: calc(100% + 1rem);
  left: 0;
  width: 100%;
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
  height: calc(100vh - 8.5rem);
`

styles.SearchResultOnFirstRow = styled.div`
  position: fixed;
  margin-top: 0.5rem;
  z-index: 1000;
  left: 0;
  width: 100vw;
  height: calc(100vh - 6.7rem);
  background-color: #ffffff;
  padding: 0;
  padding-bottom: 9rem;
  border: ${props => (props.hideBorder ? 'none' : '1px solid #eaeaea')};
  border-radius: 4px;
  display: ${props => (props.isShown ? 'flex' : 'none')};
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  ${SCREEN_SIZE.From.Tablet} {
    width: 100%;
    min-width: 22.5rem;
    position: absolute;
    height: auto;
    max-height: calc(100vh - 9rem);
    padding-bottom: 0;

    left: 0;
    top: calc(100% + 4px);
    box-shadow: 0 0.8125rem 0.9375rem 0 rgba(0, 0, 0, 0.12);
    margin-top: 0;
  }
`

export default styles

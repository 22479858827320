import React, { Component, Fragment } from 'react'
import { withRouter } from 'next/router'
import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import { CartConsumer } from '../../CartProvider'
import Text from '../../Text'
import DeliveryMeter from '../DeliveryMeter/DeliveryMeter'
import TextIcon from './../../TextIcon'
import dynamic from 'next/dynamic'
const AddressPopup = dynamic(() =>
  import('../../../components/CheckoutAddressProvider/AddressPopup')
)
import { SCREEN_SIZE } from '../../../lib/Media'
import LocationIcon from '../../icons/LocationNew'
import Expand from '../../icons/ExpandNew'
import { CheckoutAddressConsumer } from '../../../components/CheckoutAddressProvider'
import { disablePageScroll } from '../../../lib/utils'

const StyledPcmForm = styled.div`
  ${SCREEN_SIZE.From.Tablet} {
    position: relative;
  }
`
const SpacedBlock = styled.span`
  padding: 0 0.125rem 0 0;
  font-weight: ${props => (props.notBold ? '500' : '600')};
`

const ColorTag = styled.span`
  & span {
    color: ${props =>
      props.$isB2B && !props.$isInsidePcm
        ? '#fff'
        : defaultTheme.ColorBaseMineShaft700};
  }
`

const Pincode = styled(SpacedBlock)`
  color: ${props =>
    props.$isB2B && !props.$isInsidePcm
      ? '#fff'
      : defaultTheme.ColorBaseMineShaft700};
`

const DefaultStorePincode = styled(SpacedBlock)`
  color: ${props =>
    props.$isInsidePcm
      ? defaultTheme.ColorBaseBrandPrimary500
      : /* istanbul ignore next */
      props.$isB2B && !props.$isInsidePcm
      ? '#fff'
      : defaultTheme.ColorBaseMineShaft700};
`

const SelectContainer = styled.div`
  width: 100%;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.43;
  color: ${props =>
    props.$isInsidePcm ? '#333' : props.$deliveryModeB2B ? '#FFF' : '#333'};
`

const StyledText = styled(Text)`
  margin: 0 auto;
  line-height: 1.43;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 0.75rem;
  ${SCREEN_SIZE.From.MobileL} {
    font-size: 0.875rem;
  }
`

const AddressContent = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 1rem;

  ${SCREEN_SIZE.From.Desktop} {
    margin-right: 1.5rem;
  }
`

const StyledExpandIcon = styled(Expand)`
  ${SCREEN_SIZE.Only.Mobile} {
    display: none;
  }

  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
  color: ${props =>
    props.$isInsidePcm
      ? '#696969'
      : props.$deliveryModeB2B
      ? '#FFF'
      : '#696969'};
`

const StyledLocationIcon = styled(LocationIcon)`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
  color: ${props =>
    props.$isInsidePcm
      ? defaultTheme.ColorBaseBrandPrimary500
      : props.$deliveryModeB2B
      ? '#FFF'
      : defaultTheme.ColorBaseMineShaft500};
`
const SmSpacedBlock = styled.span`
  font-weight: bold;
  color: ${defaultTheme.ColorBaseMineShaft700};
`
class PcmForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisiblePopover: false,
    }

    this.openPopover = this.openPopover.bind(this)
    this.handleClosePopover = this.handleClosePopover.bind(this)
    this.handleTogglePopover = this.handleTogglePopover.bind(this)
  }

  openPopover() {
    this.setState({ isVisiblePopover: true })
  }

  handleClosePopover() {
    this.setState({ isVisiblePopover: false })
  }

  handleTogglePopover() {
    this.state.isVisiblePopover ? this.handleClosePopover() : this.openPopover()
    if (this.props.handleClickTracking) {
      this.props.handleClickTracking('PCM')
    }
  }

  componentDidMount() {
    if (this.props?.router?.asPath?.includes('/pcm')) {
      this.setState({ isVisiblePopover: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.tooltipSeeAllOptions !== this.props.tooltipSeeAllOptions &&
      !!this.props.tooltipSeeAllOptions
    ) {
      this.handleTogglePopover()
    }

    if (
      this.props.isDesktopView &&
      prevState.isVisiblePopover !== this.state.isVisiblePopover &&
      !this.state.isVisiblePopover
    ) {
      disablePageScroll(false)
    }
    const prevUrl = prevProps?.router?.asPath
    const currentUrl = this.props?.router?.asPath
    if (prevUrl !== currentUrl && currentUrl?.includes('/pcm')) {
      this.setState({ isVisiblePopover: true })
    }
  }

  render() {
    const {
      checkoutAddress,
      isLoggedIn,
      organizationData = {},
      isDesktopView,
      deliveryModeB2B,
      loading,
      additionalCharges,
      count,
    } = this.props
    const { pincode } = checkoutAddress
    const { isVisiblePopover } = this.state
    return (
      <React.Fragment>
        {isVisiblePopover && (
          <CheckoutAddressConsumer>
            {({ checkoutAddress, update }) => (
              <AddressPopup
                checkoutAddress={checkoutAddress}
                onHandleClose={this.handleClosePopover}
                updateCheckoutAddress={(
                  checkoutAddress,
                  syncCart,
                  isAddressManuallyChanged
                ) => {
                  update(checkoutAddress, syncCart, isAddressManuallyChanged)
                }}
                isLoggedIn={isLoggedIn}
                isDesktopView={isDesktopView}
              />
            )}
          </CheckoutAddressConsumer>
        )}
        <StyledPcmForm>
          <SelectContainer
            $isInsidePcm={!isDesktopView}
            $deliveryModeB2B={deliveryModeB2B}
            onClick={this.handleTogglePopover}
            data-testid="pcmModule"
          >
            {isDesktopView && isVisiblePopover && disablePageScroll(true)}
            <AddressContent>
              {pincode ? (
                <React.Fragment>
                  <TextIcon
                    prefix={
                      <StyledLocationIcon
                        $deliveryModeB2B={deliveryModeB2B}
                        $isInsidePcm={!isDesktopView}
                      />
                    }
                    suffix={
                      <StyledExpandIcon
                        $deliveryModeB2B={deliveryModeB2B}
                        $isInsidePcm={!isDesktopView}
                      />
                    }
                  >
                    <div>
                      <StyledText>
                        {checkoutAddress?.storeId !==
                        organizationData.defaultStoreId ? (
                          <Fragment>
                            <Pincode
                              notBold
                              $isB2B={deliveryModeB2B}
                              $isInsidePcm={!isDesktopView}
                            >{`${pincode}`}</Pincode>
                            <ColorTag
                              $isB2B={deliveryModeB2B}
                              $isInsidePcm={!isDesktopView}
                            >
                              <SmSpacedBlock>
                                from your nearby store
                              </SmSpacedBlock>
                            </ColorTag>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <DefaultStorePincode
                              $isB2B={deliveryModeB2B}
                              $isInsidePcm={!isDesktopView}
                              notBold
                            >
                              {`${pincode}`}
                            </DefaultStorePincode>
                          </Fragment>
                        )}
                      </StyledText>
                      {!isDesktopView && (
                        <DeliveryMeter
                          deliveryModeB2B={deliveryModeB2B}
                          loading={loading}
                          organizationData={organizationData}
                          additionalCharges={additionalCharges}
                          count={count}
                          isInsidePcm={true}
                          arrowLeft="16.4625rem"
                          tooltipLeft="-15.4625rem"
                          query={SCREEN_SIZE.Below.MobileL}
                        />
                      )}
                    </div>
                  </TextIcon>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TextIcon
                    prefix={
                      <StyledLocationIcon
                        $deliveryModeB2B={deliveryModeB2B}
                        $isInsidePcm={!isDesktopView}
                      />
                    }
                    suffix={
                      <StyledExpandIcon
                        $deliveryModeB2B={deliveryModeB2B}
                        $isInsidePcm={!isDesktopView}
                      />
                    }
                  >
                    <div>
                      <StyledText
                        size="medium"
                        weight="normal"
                        color={
                          !isDesktopView
                            ? '#333'
                            : deliveryModeB2B
                            ? '#FFF'
                            : '#333'
                        }
                        align="left"
                      >
                        Enter your address or postal code
                      </StyledText>
                      {!isDesktopView && (
                        <DeliveryMeter
                          isInsidePcm={true}
                          deliveryModeB2B={deliveryModeB2B}
                          loading={loading}
                          organizationData={organizationData}
                          additionalCharges={additionalCharges}
                          count={count}
                          arrowLeft="16.4625rem"
                          tooltipLeft="-15.4625rem"
                          query={SCREEN_SIZE.Below.MobileL}
                        />
                      )}
                    </div>
                  </TextIcon>
                </React.Fragment>
              )}
            </AddressContent>
          </SelectContainer>
        </StyledPcmForm>
      </React.Fragment>
    )
  }
}

PcmForm.defaultProps = {
  checkoutAddress: {},
}

const PcmFormWrapper = props => (
  <CartConsumer>
    {({ isBulk }) => <PcmForm {...props} isBulk={isBulk} />}
  </CartConsumer>
)

export default withRouter(PcmFormWrapper)
